import { buyercallsActionTypes } from "../constants/buyercallsTypes";

const initialState = {
  loading: false,
  data: [],
  error: ""
};

export const buyercalls = (state = initialState, action) => {
  switch (action.type) {
    case buyercallsActionTypes.BUYERCALLS_LOADING:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case buyercallsActionTypes.BUYERCALLS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: ""
      };
    case buyercallsActionTypes.BUYERCALLS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case buyercallsActionTypes.BUYERCALLS_RESET:
      return {
        ...state,
        main: [],
      };
    default:
      return state;
  }
};
