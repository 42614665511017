import axios from "axios";
import {

  loginError,
 
  addFund,
  addFundSuccess,
  addFundError,
  updateFunds
} from "../store/actions";

export class FundDistributionService {

  static AddFund = (req) => {
    return (dispatch) => {
        dispatch(addFund());
        axios
            .post(`${process.env.REACT_APP_API_URL}/saveFunddetails`, req, {
                headers: {
                    "content-Type": "application/json",
                    session: `${localStorage.getItem("access_token")}`,
                    Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
                },
            })  
            .then((response) => {
                if (response.data.status === 1) {
                    dispatch(addFundSuccess({ status: true, msg: "" }));

                    // -------------------
                    
                    let query = req;
                    let url = 
                     `${process.env.REACT_APP_API_URL}/getBalance`;
                
                    fetch(url, {
                    method: "POST",
                    body: JSON.stringify(query),
                    headers: {
                        "content-Type": "application/json",
                        session: `${localStorage.getItem("access_token")}`,
                        Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
                    },
                    }).then(res => res.json())
                    .then(res => {
                        console.log(res,"res")
                        if (res.status === 1) {
                            dispatch(
                              updateFunds({
                                funds: res?.responsedata?.getBalance,
                                PBKey: res.responsedata.PBKey,
                              })
                            );
                            
                          } 
                    })
                    .catch(err => {
                        console.log(err)
                    })
                

                } else {
                    dispatch(addFundError({ status: false, msg: response.data.message }));
                }

            })
            .catch((error) => {
                console.log("Login error", error);
                if (error.response.data === "Unauthorized.") {
                    dispatch(loginError("Session Expired !"));
                    global.localStorage.clear();
                } else {
                    console.log("error");
                    // global.localStorage.clear();
                    dispatch(addFundError("Login error"));
                }
            });
    };
};

}
