import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DynamicTable from "../../common/DynamicTable";
import { AnalyticsService } from "../../services";
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";

const Analytics = () => {
  const backToTopRef = React.useRef(null);
  const dispatch = useDispatch();
  const [filterLoader, setFilterLoader] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reportType, setReportType] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  let token = localStorage.getItem("access_token");

  const { fromdate, todate, reporttype } = location?.state || {};

  const {
    data: analyticsRawData,
    loading,
    error,
  } = useSelector((state) => state.analytic);


  useEffect(() => {
    let req = {
      type: "getBuyerAnalytics",
      filter: {
        product: "All",
        campaign_id:  "All",
        from_date: fromdate || moment(new Date()).format("YYYY-MM-DD"),
        to_date: todate || moment(new Date()).format("YYYY-MM-DD"),
        report_type: reporttype || "summary",
        report_date: moment(new Date()).format("YYYY-MM-DD")|| "",
      },
      token: token,
    };
    setFromDate(fromdate || "");
    setToDate(todate || "");
    setReportType(reporttype || "");
    token && dispatch(AnalyticsService.AnalyticsList(req));
  }, [dispatch, token, location]);

  useEffect(() => {
    if (error) {
      setFilterLoader(false);
    }
  }, [error]);

  useEffect(() => {
    setFilterLoader(false);
  }, [analyticsRawData]);

  const handleRowClick = (
    e,
    row,
    data,
    id,
    from_date,
    to_date,
    report_date,
    report_type
  ) => {
    let clickedFromDate = fromdate || fromDate;
    let clickedToDate = todate || toDate;
    let clickedReportType = reportType || reporttype;

    if (row?.totalbillable && analyticsRawData?.responsedata) {
      const { id, report_date, transfer_type } = row;
      navigate("/analytics-tabledata", {
        state: {
          id,
          transfer_type,
          fromdate: clickedFromDate,
          todate: clickedToDate,
          report_date,
          reporttype: clickedReportType,
        },
      });
    }
  };
  // const handleRowClick = (
  //   e,
  //   row,
  //   data,
  //   id,
  //   from_date,
  //   to_date,
  //   report_date,
  //   report_type,

  // ) => {
  //   let fromdate =  "";
  //   let todate = "";
  //   let reporttype = "";

  //   if (from_date) {
  //     fromdate = from_date;
  //   } else {
  //     fromdate = fromDate;
  //   }

  //   if (to_date) {
  //     todate = to_date;
  //   } else {
  //     todate = toDate;
  //   }

  //   if (report_type) {
  //     reporttype = report_type;
  //   } else {
  //     reporttype = reportType;
  //   }

  //   if (row?.totalbillable && analyticsRawData?.responsedata) {
  //     const { id, report_date } = row;
  //     navigate("/analytics-tabledata", {
  //       state: { id, fromdate, todate, report_date, reporttype },
  //     });

  //   }
  // };
  const handleFilterGenerateBtn = (data) => {
    setFilterLoader(true);
    const filter = ["date", "campaign_id", "product", "report_type"];

    let formData = {};

    let ProductParameter = filter && filter?.find((er) => er === "product");
    let DateParameter = filter && filter?.find((er) => er === "date");
    let campaignIdParameter =
      filter && filter?.find((er) => er === "campaign_id");
    let reportTypeParameter =
      filter && filter?.find((er) => er === "report_type");

    if (DateParameter) {
      formData.from_date = moment(data?.fromDate).format("YYYY-MM-DD");
      formData.to_date = moment(data?.toDate).format("YYYY-MM-DD");
    }
    if (ProductParameter) {
      formData.product = data.selectedProduct ? data.selectedProduct : "All";
    }
    if (campaignIdParameter) {
      formData.campaign_id = data.campaign_id ? data.campaign_id : "All";
    }
    if (reportTypeParameter) {
      formData.report_type = data?.report_type ? data?.report_type : "summary";
    }

    let req = {
      type: "getBuyerAnalytics",
      filter: formData,
      token,
    };
    token && dispatch(AnalyticsService.AnalyticsList(req));
    setFromDate(data?.fromDate);
    setToDate(data?.toDate);
    setReportType(data?.report_type);
    handleRowClick(
      null,
      null,
      null,
      null,
      data?.fromDate,
      data?.toDate,
      data?.report_type
    );
  };

  return (
    <div className="m-2" ref={backToTopRef}>
      <DynamicTable
        mainLoader={loading && !filterLoader}
        backToTopRef={backToTopRef}
        formData={analyticsRawData?.responsedata}
        filterLoader={filterLoader}
        handleFilterGenerateBtn={handleFilterGenerateBtn}
        pageTitle="Analytics"
        filterData={["date", "campaign_id", "product", "report_type"]}
        mode={"data"}
        analyticColumnData={true}
        handleRowClick={handleRowClick}
      />
    </div>
  );
};

export default Analytics;
