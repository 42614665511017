import React, { useEffect, useState } from "react";
import DynamicTable from "../../common/DynamicTable";
import moment from "moment";
import Button from "react-bootstrap/Button";

import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { MainLoader } from "../../common/Loader";

const AnalysticTableData = () => {
    const [buyercallRawData, setBuyercallRawData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

const handleBackClick = () => {
    const newState = {
        ...location.state, 
    };
    console.log("New State:", newState);
    navigate("/report", { state: newState || {} }); 
};

     useEffect(() => {
        const fetchData = async () => {
            setError(null);
            setLoading(true);
            try {
                const token = localStorage.getItem("access_token");
                const id = location?.state?.id;
                const fromDate = location?.state?.fromdate || moment(new Date()).format("YYYY-MM-DD");
                const toDate = location?.state?.todate || moment(new Date()).format("YYYY-MM-DD");
                const reportDate = location?.state?.report_date;
                const reportType = location?.state?.reporttype || "summary";
                const apiUrl = `https://api.quoteconsumers.com/client/buyercalldetails?campaignid=${id}&fromdate=${fromDate}&todate=${toDate}&reportdate=${reportDate}&reporttype=${reportType}`;
                const response = await fetch(apiUrl, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        session:
                            "nzv2S3MS3HYtBQcFwahkCsSlXCTb2IGyp7YeeNzC3WLg2UDVkuP23JRtDlCS",
                        authkey: "cf7009ec-b273-4ba6-a997-6df7d644aa01",
                    },
                });

                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }

                const data = await response.json();
                setBuyercallRawData({ results: data?.responsedata?.response });
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [location]);

    if (loading) {
        return <div>
            {loading ? (
                <MainLoader />
            ) : " "}</div>
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    const columns = [
        {
            dataField: "personalDetail",
            text: "Name",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "80px", textAlign: "center" };
            },
            style: { textAlign: "center" },

            formatter: (cell, item) => {
                return (
                    <>
                        <div>
                            <b> {item.personalDetail} </b>
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "address",
            text: "Address",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "140px", textAlign: "center" };
            },
            formatter: (cell, item) => {
                return (
                    <>
                        <div>
                            {item.address}
                        </div>
                        <div>
                            {item.city},
                            {item.state},
                            {item.zipcode}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "email",
            text: "Email",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "80px", textAlign: "center" };
            },
            formatter: (cell, item) => {
                return (
                    <>
                        <div>
                            <b> {item.email} </b>
                        </div>
                    </>
                );
            },

        },
        {
            dataField: "inboundPhoneNumber",
            text: "Phone Number",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "80px", textAlign: "center" };
            },

        },
        {
            dataField: "callDateTime",
            text: "Date Time",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "80px", textAlign: "center" };
            },
        },
        {
            dataField: "callLengthInSeconds",
            text: "Length In Seconds",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "80px", textAlign: "center" };
            },
        },
        {
            dataField: "callCost",
            text: "Cost",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "80px", textAlign: "center" };
            },
        },
        {
            dataField: "callRecordingUrl",
            text: "Recording Url",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "80px", textAlign: "center" };
            },
            formatter: (cell, item) => {
                return (
                    <div>
                        <a
                            href={item.callRecordingUrl}
                            target="_blank"
                            data-tooltip-content="Recording"
                            className="btn btn-outline-primary"
                            id={"recording" + item.personalDetail}>

                            <i className="fa fa-play-circle"></i>
                        </a>

                           <Tooltip
                            place="bottom"
                            className="tool_tip_graph"
                            anchorId={"recording" + item.personalDetail}
                        />
                    </div>
                );
            },
        },
    ];
    if (location?.state?.transfer_type === "Live Transfer") {
        columns.splice(0, 3);
      }
    return (
        <>
            <div className="d-flex justify-content-between gap-2 px-2 mt-5">
                <div className="clientReportTitle mb-0">
                    <h3>Calls Details Report</h3>
                </div>

                <div className="text-end">
                    <Button
                        onClick={handleBackClick}
                        variant="primary"
                    >
                        Back
                    </Button>
                </div>
            </div>
            <DynamicTable
                mainLoader={loading}
                formData={buyercallRawData}
                mode={"data"}
                analyticrowdata={true}
                customColumnsData={columns}
            />
        </>
    );
};
export default AnalysticTableData;
