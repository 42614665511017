// actions.js

import { fundDistributionActionTypes } from "../constants/fundDistributionActionTypes";

export const addFund = () => {
  return {
    type: fundDistributionActionTypes.ADD_FUND,
  };
};

export const addFundSuccess = (payload) => {
  return {
    type: fundDistributionActionTypes.ADD_FUND_SUCCESS,
    payload,
  };
};

export const addFundError = (error) => {
  return {
    type: fundDistributionActionTypes.ADD_FUND_ERROR,
    payload: error,
  };
};

export const addFundReset = () => {
  return {
    type: fundDistributionActionTypes.ADD_FUND_RESET,
  };
};
