// reducer.js

import { FETCH_FUND_DETAILS_FAILURE, FETCH_FUND_DETAILS_REQUEST, FETCH_FUND_DETAILS_SUCCESS, fundDistributionActionTypes } from "../constants/fundDistributionActionTypes";

  const initialState = {
    AddFundLoading: false,
    AddFundData: false,
    AddFundMsg: "",
    AddFundError: "",
    loading: false,
    error: null,
  };
  
  export const fundDistributionReducer = (state = initialState, action) => {
    switch (action.type) {
      case fundDistributionActionTypes.ADD_FUND:
      return {
        ...state,
        AddFundLoading: true,
      };
    case fundDistributionActionTypes.ADD_FUND_SUCCESS:
      return {
        ...state,
        AddFundLoading: false,
        AddFundData: action.payload,
        AddFundMsg: action.payload.msg,
        AddFundError: "",
      };
    case fundDistributionActionTypes.ADD_FUND_ERROR:
      return {
        ...state,
        AddFundLoading: false,
        AddFundData: action.payload.status,
        AddFundMsg: action.payload.msg,
      };
    case fundDistributionActionTypes.ADD_FUND_RESET:
      return {
        ...state,
        AddFundLoading: false,
        AddFundData: false,
        AddFundError: "",
      };
      default:
        return state;
    }
  };
  
  