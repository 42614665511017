// import React, { useEffect, useState } from "react";
// import DynamicTable from "../../common/DynamicTable";
// import { toast } from "react-toastify";
// import { Button } from "react-bootstrap";
// import { FundDistributionService } from "../../services/funddistribution";
// import { useDispatch } from "react-redux";

// const FundDistribution = () => {
//     const [loading, setLoading] = useState(false);
//     const [buyercallRawData, setBuyercallRawData] = useState([]);
//     const [userName, setUserName] = useState("");
//     const [balance, setBalance] = useState();
//     const backToTopRef = React.useRef(null);
//     const dispatch = useDispatch();

//     const totalAmount = getEnteredAmountsTotal(buyercallRawData?.results);

//     const handleAmountChange = (e, subBuyerId) => {
//         const amount = parseFloat(e.target.value) || "";
//         if (subBuyerId) {
//             setBuyercallRawData((prev = {}) => {
//                 const newResults = prev?.results?.map((item) => {
//                     // eslint-disable-next-line eqeqeq
//                     if (item?.subBuyerId == subBuyerId) {
//                         return {
//                             ...item,
//                             enteredAmount: amount,
//                         };
//                     }
//                     return item;
//                 });
//                 let newTotal = getEnteredAmountsTotal(newResults);
//                 if (newTotal <= balance) {
//                     return {
//                         ...prev,
//                         results: newResults,
//                     };
//                 } else {
//                     toast.error("Entered amount exceeds available balance");
//                 }
//                 return prev;
//             });
//         }
//     };
//     const fetchData = async () => {
//         setLoading(true);
//         try {
//             const token = localStorage.getItem("access_token");
//             const headers = {
//                 Authorization: `Bearer ${token}`,
//                 Session: localStorage.getItem("access_token"),
//                 Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
//             };
//             const response = await fetch(
//                 `https://api.quoteconsumers.com/client/getFundDeatils`,
//                 {
//                     headers: headers,
//                 }
//             );
//             if (!response.ok) {
//                 throw new Error("Failed to fetch data");
//             }
//             const data = await response.json();
//             setBuyercallRawData({ results: data?.responsedata?.response?.subBuyer });
//             const buyerName = data?.responsedata?.response?.buyerName;
//             const TotalBalance = parseFloat(
//                 data?.responsedata?.response?.buyerTotalBalance
//             );
//             setUserName(buyerName);
//             setBalance(TotalBalance);
//             setLoading(false);
//         } catch (error) {
//             console.error("Error fetching data:", error);
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchData();
//     }, []);

//     const columns = [
//         {
//             dataField: "subBuyerName",
//             text: "Sub Buyer Name",
//             sort: true,
//             headerStyle: (colum, colIndex) => {
//                 return { width: "80px", textAlign: "center" };
//             },
//             style: { textAlign: "center" },

//             formatter: (cell, item) => {
//                 return (
//                     <>
//                         <div>
//                             <b> {item?.subBuyerName} </b>
//                         </div>
//                     </>
//                 );
//             },
//         },
//         {
//             dataField: "subBuyerBalance",
//             text: "Sub Buyer Balance",
//             sort: true,
//             headerStyle: (colum, colIndex) => {
//                 return { width: "140px", textAlign: "center" };
//             },
//             formatter: (cell, item) => {
//                 return (
//                     <>
//                         <div>{item?.subBuyerBalance}</div>
//                     </>
//                 );
//             },
//         },
//         {
//             sort: true,
//             headerStyle: (colum, colIndex) => {
//                 return { width: "140px", textAlign: "center" };
//             },
//             formatter: (cell, item) => {
//                 return (
//                     <BuyerInputs
//                         key={`${item?.subBuyerId}-${item?.enteredAmount}`}
//                         item={item}
//                     />
//                 );
//             },
//         },
//     ];

//     const BuyerInputs = ({ item = {} }) => {
//         return (
//             <>
//                 <div>

//                     <input
//                         className="form-control"
//                         type="number"
//                         placeholder="Please Enter Amount"
//                         value={item?.enteredAmount}
//                         onChange={(e) => {
//                             handleAmountChange(e, item?.subBuyerId);
//                         }}
//                         min="0"
//                         step="1"
//                         // autoFocus="autoFocus"
//                         onKeyDown={(e) => {
//                             if (e.key === "-" || e.key === "e") {
//                                 e.preventDefault();
//                             }
//                         }}
//                     />
//                 </div>
//             </>
//         );
//     };

//     const handleSave = async () => {
//         try {
//             const reqObj = {};
//             buyercallRawData?.results?.forEach((buyer) => {
//                 if (buyer.enteredAmount)
//                     reqObj[`sub_agent_balance_${buyer?.subBuyerId}`] =
//                         buyer.enteredAmount;
//             });
//             const requestBody = reqObj;
//             dispatch(FundDistributionService.AddFund(requestBody));
//             fetchData();
//         } catch (error) {
//             console.error("Error saving data:", error);
//         }
//     };
//     return (
//         <>


//             <div
//                 style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     margin: "20px 40px 0px 40px",
//                     fontSize: "20px",
//                     fontWeight: "700",
//                 }}
//             >
//                 <span>Fund Distribution : {userName}</span>
//                 <span>Balance:{balance}</span>
//             </div>


//             <div className="m-2" ref={backToTopRef}>
//                 <DynamicTable
//                     mainLoader={loading}
//                     backToTopRef={backToTopRef}
//                     formData={buyercallRawData}
//                     mode={"data"}
//                     customColumnsData={columns}
//                     pageTitle="Sub Agent Details"
//                 />
//             </div>

//             {loading ?(
// ""     
//        ) :(
//                 <>

//                 <div>
//                 <span
//                     style={{ fontSize: "20px", fontWeight: "700", marginLeft: "48%" }}
//                 >
//                     Total : {totalAmount}
//                 </span>
//             </div>

//             <div className="text-center" style={{ marginTop: "40px" }}>
//                 <Button onClick={() => handleSave()} variant="primary">
//                     Save
//                 </Button>
//             </div>
//             </>
//             )}

//         </>
//     );
// };

// export default FundDistribution;

// const getEnteredAmountsTotal = (buyerData = []) =>
//     buyerData.reduce((acc, curr) => {
//         const num = parseFloat(curr?.enteredAmount);
//         if (!isNaN(Number(num))) return parseFloat(acc) + parseFloat(num);
//         return acc;
//     }, 0);

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { FundDistributionService } from "../../services/funddistribution";
import { useDispatch } from "react-redux";
import { MainLoader } from "../../common/Loader";
import { updateFunds } from "../../store/actions";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";

const FundDistribution = () => {
    const [loading, setLoading] = useState(false);
    const [buyercallRawData, setBuyercallRawData] = useState([]);
    const [userName, setUserName] = useState("");
    const [balance, setBalance] = useState("");
    const [totalAmount, setTotalAmount] = useState(0);
    const [enteredAmounts, setEnteredAmounts] = useState({});
    const dispatch = useDispatch();
    
console.log(balance,"balance")
    const funds = async (req) => {
        let query = req;
        let url = query
          ? `${process.env.REACT_APP_API_URL}/stripe`
          : `${process.env.REACT_APP_API_URL}/getBalance`;
      
        const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify(query),
          headers: {
            "content-Type": "application/json",
            session: `${localStorage.getItem("access_token")}`,
            Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
          },
        });
      
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
      
        return response.json();
      };
    const {
        mutateAsync,
      } = useMutation(funds);

      const fundsUpdate = async () => {
        mutateAsync()
          .then((res) => {
            if (res.status === 1) {
              dispatch(
                updateFunds({
                  funds: res?.responsedata?.getBalance,
                  PBKey: res.responsedata.PBKey,
                })
              );
              fetchData();
            } else if (res?.status === 0) {
            }
          })
          .catch((err) => {});
    }
    const fetchData = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("access_token");
            const headers = {
                Authorization: `Bearer ${token}`,
                Session: localStorage.getItem("access_token"),
                Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
            };
            const response = await fetch(
                `https://api.quoteconsumers.com/client/getFundDeatils`,
                {
                    headers: headers,
                }
            );
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }
            const data = await response.json();
            setBuyercallRawData(data?.responsedata?.response?.subBuyer || []);
            setUserName(data?.responsedata?.response?.buyerName || "");
            setBalance(parseFloat(data?.responsedata?.response?.buyerTotalBalance) || 0);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };
   
    const handleAmountChange = (e, subBuyerId) => {
        const amount = parseFloat(e.target.value) || 0;
        if (!isNaN(amount)) {
            const updatedEnteredAmounts = { ...enteredAmounts, [subBuyerId]: amount };
            const total = Object.values(updatedEnteredAmounts).reduce((acc, curr) => acc + curr, 0);
            if (total <= balance) {
                setEnteredAmounts(updatedEnteredAmounts);
                setTotalAmount(total);

            } else {
                toast.error("Entered amount exceeds available balance");
            }
        }
    };
    const handleSave = async () => {
        try {
            const requestBody = {};
            Object.keys(enteredAmounts).forEach(subBuyerId => {
                requestBody[`sub_agent_balance_${subBuyerId}`] = enteredAmounts[subBuyerId];
            });
             dispatch(FundDistributionService.AddFund(requestBody));
             toast.success("Fund distribution save successfully")

            await fundsUpdate(); // Wait for fundsUpdate to complete
            // fetchData();
            setEnteredAmounts({});
            setTotalAmount(0);
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };
    
    
    useEffect(() => {
        fetchData();
    }, []);

 
    return (
        <>
            {loading ? (
                <div className="text-center">      
                  <MainLoader />
                </div>
            ) : (
                <>
                    <div style={{ display: "flex", justifyContent: "space-between", margin: "20px 40px 0px 40px", fontSize: "20px", fontWeight: "700" }}>
                        <span>Fund Distribution : {userName}</span>
                        <span>Balance:{balance}</span>
                    </div>

                    <div className="m-2">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sub Buyer Name</th>
                                    <th>Sub Buyer Balance</th>
                                    <th>Entered Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {buyercallRawData.map(item => (
                                    <tr key={item.subBuyerId}>
                                        <td>{item.subBuyerName}</td>
                                        <td>{item.subBuyerBalance}</td>
                                        <td>
                                            <input
                                                className="form-control"
                                                type="number"
                                                placeholder="Please Enter Amount"
                                                value={enteredAmounts[item.subBuyerId] || ''}
                                                onChange={(e) => handleAmountChange(e, item.subBuyerId)}
                                                min="0"
                                                step="1"
                                                onKeyDown={(e) => { if (e.key === "-" || e.key === "e") e.preventDefault(); }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <span style={{ fontSize: "20px", fontWeight: "700", marginLeft: "48%" }}>Total : {totalAmount}</span>
                    </div>

                    <div className="text-center" style={{ marginTop: "40px" }}>
                        <Button onClick={() => handleSave()} variant="primary">Save</Button>
                    </div>
                </>
            )}
        </>
    );
};

export default FundDistribution;

